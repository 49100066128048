body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Cardo" ;
  /* 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  max-width: 100% !important;
  overflow-x: hidden !important;
  background-color: #1b222e !important;
  background-size: 100%;
  background-repeat: no-repeat;
  text-transform: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Cardo",
    monospace;
}

.player-wrapper {
  position: fixed;
  /* height: 100vh !important; */
  width: 100vw !important;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  top: 0px;
  z-index: -2;
}

@media screen and (max-width: 768px) {
  .react-player {
    visibility: hidden;
    clear: both;
    float: left;
    display: none;
  }
  .destopText {
    visibility: hidden;
    clear: both;
    float: left;
    display: none;
  }
}
@media screen and (min-width: 768px) {
.react-player {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}
.destopText {
  margin-top: 20vh !important;
  position: relative;
  
}
.homeMobile{
  position: relative;
  top:-20vh;
  width: 100%;
  height: 100vh !important;
  z-index: -2;
}
}

.bgHome{
  background-color: rgba(0, 0, 0, .7);
}
.bgPic{
  background-clip: border-box;
  background-position: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: linear-gradient(#072e2d3a, #000000),
  url('../public/images/ben/ben2.webp');

  height: 100vh !important;
z-index: -7;
  left: 0;
  position: fixed;
  top:0
}

.p1{
  text-transform: lowercase !important;
}